import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Reveal from 'react-reveal/Reveal'
import Link from 'gatsby-link'

class Title extends Component {
  state = {
    top: 0,
    left: 0,
    width: 'inherit',
  }

  componentDidMount() {
    window.addEventListener('scroll', this._onScroll())
    this._onScroll()()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._onScroll())
  }

  _onScroll = () => (event) => {
    if (!this.refs.text) return false
    let { height, y, width, x } = this.refs.text.getBoundingClientRect()
    let top = ((window.outerHeight / 2.5) - ( y + (height / 2) )) * -0.05
    let left = top
    this.setState({ top, left, width })
  }

  _decodeHtml(html) {
    if (typeof document !== `undefined`) {
      let txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    }
    return ''
  }

  render() {
    let { top, left, width } = this.state

    let { text } = this.props
    text = this._decodeHtml(text)

    return (
      <h3 className="special" ref='text'>
        { text }
        <span ref='shadow' style={{top, left, width}}>{ text }</span>
      </h3>
    )
  }
}

Title.propTypes = {
  text: PropTypes.string,
}
 
export default Title
