import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'
import Reveal from 'react-reveal/Reveal'
import axios from 'axios'

import TemplateWrapper from '../components/layout'
import Title from '../components/title'

class AboutPage extends Component {

  render() {

    let data = this.props.data.wordpressPage;

    return (
      <TemplateWrapper {...this.props}>
        <Helmet bodyAttributes={{ class: 'about' }}>
          <title>{ data.yoast_meta.yoast_wpseo_title }</title>
          <meta name="description" content={ data.yoast_meta.yoast_wpseo_metadesc } />
        </Helmet>

        <div className="r3__section r3--dark r3--no-head">
          <div className="r3__content">
            <Reveal effect="fadeInLeft">
              <h1>{data.acf.title}</h1>
              <div className="r3__narrow" dangerouslySetInnerHTML={{__html: data.acf.content}} />
            </Reveal>
          </div>
        </div>

        {
          data.acf.team.map((el, i) => {
            return (
              <div className="r3__section r3__section--team" key={i}>
                <div className="r3__content">
                  <Reveal effect="fadeInUp">
                    <div className="r3__team">
                      <div className="r3__team-img">
                        <span>
                          <img src={el.image.source_url} alt={el.name} />
                        </span>
                        <h4>{el.name}</h4>
                        <p>{el.position}</p>
                        <a href={el.linkedin} target="_blank">LinkedIn</a>
                      </div>
                      <div className="r3__team-content">
                        <Title text={el.tagline} />
                        <div className="r3__narrow" dangerouslySetInnerHTML={{__html: el.bio}} />
                      </div>
                    </div>
                  </Reveal>
                </div>
              </div>
            )
          })
        }

        { data.acf.secondary_title && data.acf.secondary_content &&
          <div className="r3__section r3--dark">
            <div className="r3__content r3__narrow r3__narrow--right">
              <Reveal effect="fadeInUp">
                <h3>{data.acf.secondary_title}</h3>
                <div dangerouslySetInnerHTML={{__html: data.acf.secondary_content}} />
              </Reveal>
            </div>
          </div>
        }

        { data.acf.awards &&
          <div className="r3__section">
            <div className="r3__content">
              <Reveal effect="fadeInLeft">
                <h3>{data.acf.awards_title}</h3>
                <div className="r3__partners">
                  { data.acf.awards.map((el, i) => {
                    return (
                      <a href={el.link} target="_blank" key={i}><img alt={el.image.title} src={el.image.source_url} /></a>
                    )
                  })}
                </div>
              </Reveal>
            </div>
          </div>
        }

      </TemplateWrapper>
    )
  }
}

export default AboutPage

export const pageQuery = graphql`
  query aboutQuery {
    wordpressPage(title: {eq: "About"}) {
      title
      acf {
        title
        content
        secondary_title
        secondary_content
        awards_title
        awards {
          link
          image {
            source_url
            title
          }
        }
        team {
          name
          position
          linkedin
          tagline
          bio
          image {
            source_url
          }
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
  }
`
